import { flowRight, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostHeader from '../post-header';
import PostTitle from '../post-title';
import PostSubTitle from '../post-sub-title';
import PostHashtags from '../post-hashtags';
import PostFooter from '../post-footer';
import ContentExcerpt from '../content-excerpt';
import { getCategory } from '../../../common/selectors/categories-selectors';
import contentStateToString from '../../services/content-state-to-string';
import {
  getIsListHashtagsEnabled,
  getIsViewsCountEnabled,
} from '../../selectors/app-settings-selectors';
import Link from '../link/internal-link';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import styles from './post-list-item.scss';
import { isPostSeen } from '../../services/post-utils';
import PostCoverMedia from '../post-cover-media';
import { mapTypeToSize } from '../post-image/utils';
import { getIsPostNew } from '../../containers/post-activity/post-activity.selectors';

export const PostListItem = ({
  type,
  category,
  post,
  query,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  forPublicUser,
  onLikeClick,
  isViewsCountEnabled,
  isListHashtagsEnabled,
  showCategoryLink,
  showContent,
  showMedia,
  showPinIcon,
  showBestAnswer,
  showMoreButton,
  showViewCount,
  showRecentActivity,
  origin,
  getIsPostNew,
}) => {
  const className = classNames(
    styles.container,
    styles[type],
    contentFontClassName,
    'forum-text-color',
    'forum-card-background-color',
    'forum-card-border-color',
    'post-list-item',
  );

  const postLink = `/${category.slug}/${post.slug}`;
  const hashtags = get(post, 'hashtags');
  const content = contentStateToString(post.content);
  const isEmptyContent = content.trim() === '';

  return (
    <div className={className} style={{ borderWidth }} role="article" data-hook="post-list-item">
      <PostHeader
        post={post}
        isPostNew={getIsPostNew(post._id)}
        category={category}
        type={type}
        showLastActivityDate={type !== PostListItem.MEMBERS_AREA}
        showDateBelowAvatar={false}
        showAvatarBadges
        showPinIcon={showPinIcon}
        showLockIcon={false}
        showBestAnswer={showBestAnswer && post}
        showMoreButton={showMoreButton}
        origin={origin}
        showUserInfoInline={type === PostListItem.SEARCH}
        showNewPostIndicator
      />
      <PostTitle
        className={classNames(titleFontClassName, 'forum-text-color')}
        isPostSeen={isPostSeen(post)}
        isPostNew={getIsPostNew(post._id)}
        type={type}
        title={post.title}
        query={query}
        to={postLink}
        post={post}
        category={category}
        showBestAnswer={false}
      />
      <PostSubTitle
        post={post}
        type={type}
        category={category}
        showCategoryLink={showCategoryLink}
      />
      {showContent && !isEmptyContent && (
        <div className={classNames(styles.content)}>
          <Link to={postLink} className="forum-text-color" tabIndex="-1">
            <ContentExcerpt
              type={type}
              content={content.slice(0, 300)}
              query={query}
              isContentConverted
            />
          </Link>
        </div>
      )}
      {isListHashtagsEnabled && !isEmpty(hashtags) && (
        <PostHashtags type={type} hashtags={hashtags} />
      )}

      {showMedia && (
        <PostCoverMedia post={post} postLink={postLink} width={mapTypeToSize(type).width}>
          {media =>
            media && (
              <Link to={postLink}>
                <div className={styles.coverMedia}>{media}</div>
              </Link>
            )
          }
        </PostCoverMedia>
      )}

      <div className={classNames({ [styles.extraFooterSpacing]: type === PostListItem.LARGE })}>
        <PostFooter
          type={type}
          forPublicUser={forPublicUser}
          onLikeClick={onLikeClick}
          post={post}
          category={category}
          Link={Link}
          postLink={postLink}
          isViewsCountEnabled={isViewsCountEnabled && showViewCount}
          isListHashtagsEnabled={isListHashtagsEnabled}
          showSeparator={true}
          showRecentActivity={showRecentActivity}
        />
      </div>
    </div>
  );
};

PostListItem.SMALL = 'small';
PostListItem.MEDIUM = 'medium';
PostListItem.LARGE = 'large';
PostListItem.SEARCH = 'search';
PostListItem.MEMBERS_AREA = 'membersArea';
PostListItem.MOBILE = 'mobile';

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  t: PropTypes.func,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  type: PropTypes.string,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  can: PropTypes.func,
  navigation: PropTypes.object,
  forPublicUser: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  isListHashtagsEnabled: PropTypes.bool.isRequired,
  showCategoryLink: PropTypes.bool,
  showContent: PropTypes.bool,
  showPinIcon: PropTypes.bool,
  showBestAnswer: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showViewCount: PropTypes.bool,
  showRecentActivity: PropTypes.bool,
  origin: PropTypes.string.isRequired,
  getIsPostNew: PropTypes.func,
};

PostListItem.defaultProps = {
  showContent: true,
  showMedia: true,
  showPinIcon: true,
  showBestAnswer: true,
  showMoreButton: true,
  showViewCount: true,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, ownProps.post.categoryId),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  isListHashtagsEnabled: getIsListHashtagsEnabled(state, host.style),
  getIsPostNew: getIsPostNew(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withCardBorderWidth,
  withFontClassName,
  withPermissions,
  withAuth,
)(PostListItem);
